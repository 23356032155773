import { Typography, Button, Drawer, List, Badge, } from 'antd';
import { NotificationsModel } from 'src/models/NotificationModel';
import moment from 'moment';
import { DateFormat } from 'src/constant/DateFormatConstants';
import { useCallback, useEffect, useState } from 'react';
import { NotificationsService } from '../../services/notificationsService';
import { HttpStatusCode } from 'axios';
import signalRService from 'src/services/signalrService';

interface DrawerInterface {
  isOpen: boolean;
  onClose: any;
  countNotify: (count: number) => void;
}

export default function NotificationAlive({ isOpen, onClose, countNotify }: DrawerInterface) {
  const [notifications, setNotifications] = useState<NotificationsModel[]>([]);

  const notificationsService = new NotificationsService();

  const getNotificationsAsync = useCallback(async () => {
    const { data, status } = await notificationsService.getNotificationsAsync();

    if (status !== HttpStatusCode.Ok) {
      return;
    }

    if (data.length <= 0) {
      return;
    }

    const dataUnread = data.filter((item) => !item.isRead).length;

    setNotifications(data);
    countNotify(dataUnread);
  }, []);

  const updateNotificationsAsync = useCallback(async (
    notiId: string,
    title: string,
    message: string) => {
    setNotifications(prev => {
      const newNoti = [
        {
          id: notiId,
          title,
          message,
          uri: '',
          createdAt: moment(new Date()).format(DateFormat.DateTimeDash),
          isRead: false
        }, ...prev];
      const dataUnread = newNoti.filter((item) => !item.isRead).length;

      countNotify(dataUnread);
      return newNoti;
    });
  }, []);

  useEffect(() => {
    getNotificationsAsync();
  }, []);

  useEffect(() => {
    const handleNotificationReceived = (
      notiId: string,
      title: string,
      message: string) => {

      if (title === signalRService.deActiveTitle) {
        return;
      }

      updateNotificationsAsync(
        notiId,
        title,
        message);
    };

    signalRService.hubConnection?.on(signalRService.notificationReceived, handleNotificationReceived);

    return () => {
      signalRService.hubConnection?.off(signalRService.notificationReceived, handleNotificationReceived);
    };
  }, []);

  const onClickMarkAllReadAsync = async () => {
    const { status } = await notificationsService.markAllAsReadAsync();

    if (status !== HttpStatusCode.Ok && status !== HttpStatusCode.NoContent) {
      return;
    }

    getNotificationsAsync();
  };

  const markAsRead = async (id: string) => {
    const { status } = await notificationsService.markAsReadAsync(id);

    if (status !== HttpStatusCode.Ok && status !== HttpStatusCode.NoContent) {
      return;
    }

    getNotificationsAsync();
  };

  return (
    <>
      <Drawer
        title="Notification"
        placement="right"
        onClose={onClose}
        open={isOpen}
        extra={
          <Button type="link" size='large' onClick={onClickMarkAllReadAsync}>
            Mark All Read
          </Button>
        }
      >
        <List
          itemLayout="horizontal"
          size="small"
          dataSource={notifications}
          renderItem={(item, index) => (
            <List.Item>
              <div className='noti-item-box'>
                <div className='noti-item-box-head'>
                  {
                    item.isRead
                      ?
                      <span className='noti-head-left'>
                        {item.title}
                      </span>
                      :
                      <span
                        className='noti-head-left'
                        style={{ cursor: 'pointer' }}
                        onClick={() => markAsRead(item.id)}>
                        <Badge color={'blue'}></Badge> {item.title}
                      </span>
                  }
                  <span className='noti-head-right'>
                    {item.createdAt && moment(item.createdAt).format(DateFormat.DateTimeDash)}
                  </span>
                </div>
                <div className='noti-item-box-message'>
                  {item.message}
                </div>
              </div>
            </List.Item>
          )}
        />
      </Drawer>
    </>
  );
}

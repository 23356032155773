import sessionStore from "../store/sessionStore";
import { Session } from "../constant/sesstion";
import { HubConnection, HubConnectionBuilder, HttpTransportType, HubConnectionState } from "@microsoft/signalr";

const notificationUrl = process.env.REACT_APP_NOTIFICATION_URL;

class SignalRService {
    private static instance: SignalRService;
    public hubConnection: HubConnection | null = null;
    private signOutFunction: (() => void) | null = null;

    public notificationReceived = "NotificationReceived";
    public deActiveTitle = "Alive United Account Deactivation.";

    private constructor() {
        this.reconnectLogic();
    }

    public static getInstance(): SignalRService {
        if (!SignalRService.instance) {
            SignalRService.instance = new SignalRService();
        }

        return SignalRService.instance;
    }

    private async reconnectLogic() {
        const session = sessionStore.get(Session.SESSION_ACCESS_TOKEN);
        if (!session) {
            return;
        }

        if (this.hubConnection && this.hubConnection.state === HubConnectionState.Connected) {
            return;
        }

        const accessToken = `${session.token_type} ${session.access_token}`;

        this.hubConnection = new HubConnectionBuilder()
            .withUrl(notificationUrl + '', {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets,
                accessTokenFactory: () => accessToken
            })
            .withAutomaticReconnect()
            .build();

        this.startConnection();
    }

    public async startConnection() {
        if (!this.hubConnection) {
            return;
        }

        try {
            await this.hubConnection.start();

            this.registerEvents();
        } catch (err) {
            console.error("*** SignalR Connection Error:", err);
        }
    }

    registerEvents() {
        if (!this.hubConnection) return;

        this.hubConnection.on(this.notificationReceived, (
            notiId: string, title: string, message: string,
        ) => {
            if (title === this.deActiveTitle && this.signOutFunction) {
                this.signOutFunction();
            }
        });
    }

    public async stopConnection() {
        if (this.hubConnection && this.hubConnection.state === HubConnectionState.Connected) {
            await this.hubConnection.stop();
        }
    }

    public restartConnection(signOutFunc: () => void) {
        this.signOutFunction = signOutFunc;

        this.stopConnection().then(() => this.reconnectLogic());
    }
}

export default SignalRService.getInstance();

import { HttpStatus } from '../constant/responseStatus';
import { Session, SessionTimeOut } from '../constant/sesstion';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Axios from './Axios';
import { AccountService } from '../services/accountService';
import { ErrorType } from '../constant/errorType';
import {
    ResponseSignIn,
    SignInFacebookModel,
    SignInGoogleModel,
    SignInModel,
    SignInResponseModel,
} from '../models/accountModel';
import sessionStore from '../store/sessionStore';
import { UserProfileResponse } from '../models/responses/userProfileResponse';
import { message } from 'antd';
import signalRService from '../services/signalrService';

const refreshTokenAsync = async (error?: AxiosError) => {
    const token = sessionStore.get(Session.SESSION_ACCESS_TOKEN) as SignInResponseModel;
    const refreshToken = sessionStore.get(Session.SESSION_REFRESH_TOKEN);


    if (!token) {
        signOut();

        return null;
    }

    const response = await axios({
        baseURL: `${process.env.NEXT_PUBLIC_API_URL}/account/refresh-token`,
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        data: {
            refreshToken: refreshToken,
            clientId: 'VFun-Web',
        },
    });

    if (response.status !== HttpStatus.OK) {
        signOut();

        return null;
    }

    const signInResponse = {
        access_token: response.data.access_token,
        expires_in: response.data.expires_in,
        token_type: response.data.token_type,
    } as SignInResponseModel;

    const expireMillisecond = signInResponse.expires_in * 1000;

    sessionStore.set(Session.SESSION_ACCESS_TOKEN, signInResponse, expireMillisecond);
    sessionStore.set(Session.SESSION_REFRESH_TOKEN, response.data.refresh_token, SessionTimeOut.ONE_WEEK);
    sessionStore.set(Session.EXPIRE_SESSION, calculationTokenExpire(token.expires_in), SessionTimeOut.ONE_WEEK);

    if (error) {
        // Todo:
        Axios(error.config as any);
    }

    return signInResponse;
};

const signInAsync = async (signinModel: SignInModel) => {
    const accountService = new AccountService();
    const response = await accountService.signInAsync(signinModel.username, signinModel.password);

    return await setSignInAsync(response);
};

const signInGoogleAsync = async (signinModel: SignInGoogleModel) => {
    const accountService = new AccountService();
    const response = await accountService.sigInGoogleAsync(signinModel);

    return await setSignInAsync(response);
};

const signInFacebookAsync = async (signinModel: SignInFacebookModel) => {
    const accountService = new AccountService();
    const response = await accountService.sigInFacebookAsync(signinModel);

    return await setSignInAsync(response);
};

const setSignInAsync = async (response: AxiosResponse) => {
    if (response.status === HttpStatus.NOT_FOUND) {
        return {
            type: ErrorType.ERROR,
            msg: response.data,
            status: response.status,
        } as ResponseSignIn;
    }

    if (response.status === HttpStatus.BAD_REQUEST) {
        const errorDesc = response?.data?.error_description;

        if (errorDesc && errorDesc === 'Device code missing.') {
            return {
                type: ErrorType.ERROR_2FA,
                msg: response?.data?.error_description,
                status: response.status,
            } as ResponseSignIn;
        }

        return {
            type: ErrorType.ERROR,
            msg: 'Invalid user or incorrect password.',
            status: response.status,
        } as ResponseSignIn;
    }

    if (response.status === HttpStatus.FORBIDDEN) {
        return {
            type: ErrorType.ERROR,
            msg: 'The user does not have permission to log in.',
            status: response.status,
        } as ResponseSignIn;
    }

    if (response && response.status !== HttpStatus.OK) {
        return { type: ErrorType.ERROR, msg: 'can not login', status: response.status } as ResponseSignIn;
    }

    const signInResponse = {
        access_token: response.data.access_token,
        expires_in: response.data.expires_in,
        token_type: response.data.token_type,
    } as SignInResponseModel;

    const expireMillisecond = signInResponse.expires_in * 1000;

    sessionStore.set(Session.SESSION_ACCESS_TOKEN, signInResponse, expireMillisecond);
    sessionStore.set(Session.SESSION_REFRESH_TOKEN, response.data.refresh_token, SessionTimeOut.ONE_WEEK);
    sessionStore.set(
        Session.EXPIRE_SESSION,
        calculationTokenExpire(signInResponse.expires_in),
        SessionTimeOut.ONE_WEEK
    );

    const accountService = new AccountService();

    const userInfoResponse = await accountService.getUserProfileAsync();

    if(userInfoResponse.data.isVerified === false){
        message.open({
            type: 'warning',
            content: 'Please verify your e-mail ',
        });
    }

    const userInfo = userInfoResponse.data as UserProfileResponse;

    sessionStore.set(Session.SESSION_USER, userInfo);

    signalRService.restartConnection(signOut);

    return { type: 'success', status: response.status, data: response.data as SignInResponseModel } as ResponseSignIn;
};

const calculationTokenExpire = (expireIn: number) => {
    return Date.now() + expireIn * 1000;
};

const signOut = (routeTo: string = '/') => {
    sessionStore.remove(Session.SESSION_ACCESS_TOKEN);
    sessionStore.remove(Session.SESSION_REFRESH_TOKEN);
    sessionStore.remove(Session.SESSION_USER);
    sessionStore.remove(Session.SESSION_HOST);
    sessionStore.remove(Session.EXPIRE_SESSION);

    signalRService.stopConnection();

    if (routeTo) {
        window.location.href = routeTo;
    }

    window.location.href = '/';
};

const profileAsync = async () => {
    const accountService = new AccountService();
    return await accountService.getUserProfileAsync();
};

export default {
    refreshTokenAsync,
    signInAsync,
    signInGoogleAsync,
    signInFacebookAsync,
    signOut,
    profileAsync,
};

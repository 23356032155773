import { Col, Row, Tabs, TabsProps, Card } from 'antd';
import { HttpStatus } from '../../../src/constant/responseStatus';
import { useEffect, useState } from 'react';
import { UserAssetLicenseService } from '../../../src/services/userAssetLicenseService';
import { RouteNames } from 'src/routes/routeName';
import { FillterAssetType } from 'src/constant/assetConstants';
import GalleryItem from 'src/components/galleryItem/GalleryItem';
import { PageDocument } from 'src/constant/stringConstants';
import _ from 'lodash';
import { AssetPublishModel } from '@/src/models/assetModel';

export default function MyAsset() {
  const [, setTabValue] = useState<string>(FillterAssetType.All);
  const [select,] = useState<string>('newest');
  const [userAssetLicenses, setUserAssetLicense] = useState<AssetPublishModel[]>([]);

  const Tabitems: TabsProps['items'] = [
    {
      key: FillterAssetType.All,
      label: `All`,
      children: renderGallery(userAssetLicenses),
    },
    {
      key: FillterAssetType.IMAGE,
      label: `Photos`,
      children: renderGallery(
        userAssetLicenses.filter((x) => x.assetType === FillterAssetType.IMAGE)
      ),
    },
    {
      key: FillterAssetType.VIDEO,
      label: `Videos`,
      children: renderGallery(
        userAssetLicenses.filter((x) => x.assetType === FillterAssetType.VIDEO)
      ),
    },
  ];

  useEffect(() => {
    document.title = `Profile - ${PageDocument.Title}`;
    getAllAssetAsync(select);
  }, []);

  const getAllAssetAsync = async (sort: string) => {
    const response = await new UserAssetLicenseService().getAllAsync(sort);

    if (response.status !== HttpStatus.OK) {
      return;
    }

    const data = response.data;
    const GrpAsset = _(data)
      .groupBy((item) => {
        return item.assetPublishDetailModel.id;
      })
      .map(function (userAssetLicenses, assetId) {
        return { assetId: assetId, userAssetLicenses: userAssetLicenses };
      })
      .value();

    let assetDatas: AssetPublishModel[] = [];
    GrpAsset.forEach(uLicense => {
      assetDatas.push(uLicense.userAssetLicenses[0].assetPublishDetailModel);
    });
    setUserAssetLicense(assetDatas);
  };

  const onChange = (newActiveKey: string) => {
    setTabValue(newActiveKey);
  };

  function renderGallery(userAssetLicenseList: AssetPublishModel[]) {
    const onClickItem = (assetId: string) => {
      window.location.href = RouteNames.assetDetail.replace(':id', assetId);
    };

    return (
      <>
        <Row gutter={16}>
          {userAssetLicenseList &&
            userAssetLicenseList.map((d, index) => {
              return (
                <Col key={index} className='mb-3'>
                  <GalleryItem
                    index={index}
                    asset={d}
                    width={'120px'}
                    height={'120px'}
                    onClickItem={onClickItem}
                    btnDelmarginTop={10}
                    btnDelmarginRight={20}
                  />
                </Col>
              );
            })}
        </Row>
      </>
    );
  }

  return (
    <>
      <Card bordered={false}>
        <Tabs
          defaultActiveKey={FillterAssetType.All}
          tabPosition={'top'}
          style={{ height: '80vh' }}
          onChange={onChange}
          items={Tabitems}
          className='fix-ma'
        />
      </Card>
    </>
  );
}
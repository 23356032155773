import { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Button, Col, Form, Input, InputRef, message, Row } from 'antd';
import TranslationKey from 'src/i18n/translation';

interface ModalInterface {
  isModalOpen: boolean;
  onClose: any;
  sharedUrl: string;
}

export default function ShareModal({ isModalOpen = false, onClose, sharedUrl }: ModalInterface) {
  const textInputRef = useRef<InputRef>(null);
  const [btnText, setBtnText] = useState(TranslationKey('assetDetail.share.copyToShare'));
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldValue('shareUrl', sharedUrl);
  }, [sharedUrl]);

  useEffect(() => {
    if (isModalOpen) {
      setBtnText(TranslationKey('assetDetail.share.copyToShare'));
    }
  }, [isModalOpen]);

  const onFinish = (values: any) => {
    textInputRef.current!.focus({ cursor: 'all', });
    navigator.clipboard.writeText(sharedUrl);
    setBtnText(TranslationKey('assetDetail.share.copiedToClipboard'));

    message.open({
      type: 'success',
      content: TranslationKey('assetDetail.share.copiedToClipboard'),
    });
  };

  return (
    <Modal
      size='lg'
      show={isModalOpen}
      onHide={onClose}
      dialogClassName='fav-modal'>
      <Modal.Header>
        {TranslationKey('assetDetail.share.modalTitle')}
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col span={24}>
            <Form form={form}
              name='form_item_path'
              layout='vertical'
              onFinish={onFinish}
              className='form-input'>
              <Row gutter={[3, 1]}>
                <Col span={24} lg={18}>
                  <Form.Item
                    name='shareUrl'
                    className='text-light'
                  >
                    <Input
                      ref={textInputRef}
                      className='input-radius-dark w-100'
                      readOnly size='large' />
                  </Form.Item>
                </Col>
                <Col span={24} lg={6}>
                  <Button
                    type='primary'
                    size='large'
                    shape='round'
                    className='btn-primary'
                    htmlType='submit'
                    block>
                    {btnText}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

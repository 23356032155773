import { Col, Divider, Form, FormInstance, Input, Row, Switch, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import ICompanyResponse from '../../models/responses/companyResponse';
import { useCallback, useEffect, useState } from 'react';
import SelectProvince from '../../components/address/SelectProvince';
import SelectDistrict from '../../components/address/SelectDistrict';
import SelectSubDistrict from '../../components/address/SelectSubDistrict';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import TranslationKey from 'src/i18n/translation';

const { Title } = Typography;
interface IFormCompany {
  useFormCompany: FormInstance<any>;
  onSubmitCompany: (data: any) => void;
  companyInfo: ICompanyResponse | undefined;
}

const FormCompany = ({ useFormCompany, onSubmitCompany, companyInfo }: IFormCompany) => {
  const [postcode, setPostCode] = useState('');
  const [districtId, setDistrictId] = useState(0);
  const [provinceId, setProvinceId] = useState(0);
  const [totalVipDownload, setTotalVipDownload] = useState(0);
  const [vipDownloadQty, setVipDownloadQty] = useState(0);
  const [subDistrictId, setSubDistrictId] = useState(0);

  const [form] = Form.useForm(useFormCompany);

  useEffect(() => {
    if (companyInfo) {
      if (companyInfo?.vipDownloads) {
        setTotalVipDownload(companyInfo?.vipDownloads.reduce((a, v) => a = a + v.quantity, 0));
      }
      if (companyInfo?.vipDownloadQty) {
        setVipDownloadQty(companyInfo?.vipDownloadQty);
      }
      setProvinceId(companyInfo.addressInfo.provinceCode);
      setDistrictId(companyInfo.addressInfo.districtCode);
      setSubDistrictId(companyInfo.addressInfo.subDistrictCode);
      setPostCode(companyInfo.addressInfo.postcode.toString());
    }
  }, [companyInfo]);

  const validateCustomField = useCallback((isRequired: boolean, key: string) => {
    if (isRequired) {
      useFormCompany.setFields([
        {
          name: key,
          errors: [`${key} is required`],
        },
      ]);
    } else {
      useFormCompany.setFields([
        {
          name: key,
          errors: undefined,
        },
      ]);
    }
  }, [useFormCompany]);

  const onProvinceChange = (provinceId: number) => {
    setProvinceId(provinceId);
    useFormCompany.setFieldValue('provinceCode', provinceId);
    validateCustomField(!provinceId, 'provinceCode');
  };

  const onDistrictChange = (districtId: number) => {
    setDistrictId(districtId);
    useFormCompany.setFieldValue('districtCode', districtId);
    validateCustomField(!districtId, 'districtCode');
  };

  const onSubDistrictChange = (subDistrictId: number) => {
    setSubDistrictId(subDistrictId);
    useFormCompany.setFieldValue('subDistrictCode', subDistrictId);
    validateCustomField(!subDistrictId, 'subDistrictCode');
  };

  return (
    <Content>
      <Form
        form={form}
        onFinish={onSubmitCompany}
        wrapperCol={{ span: 23 }}
        name="form_item_path"
        layout="vertical"
        className="form-input">
        <Row gutter={[16, 16]} className="artist-upload">
          <Col xs={24} lg={8} className="flex-center-items">
            <Form.Item
              name="companyName"
              className="w-100 text-light"
              label={TranslationKey('company.title')}
              rules={[{
                required: true,
                message: TranslationKey('company.requireCompanyName')
              }]}>
              <Input
                className="w-100"
                placeholder="example"
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8} className="flex-center-items">
            <Form.Item
              name="taxId"
              className="w-100 text-light"
              label={TranslationKey('company.taxId')}
              rules={[{
                required: true,
                message: TranslationKey('company.requireTaxId')
              }]}>
              <Input
                className="w-100"
                placeholder="Tax ID"
                size="large" />
            </Form.Item>
          </Col>
          {
            companyInfo?.isVip && (
              <Col xs={24} lg={8} className="flex-center-items">
                <Form.Item
                  className="w-100"
                  label="VIP">
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    defaultChecked
                    disabled
                  />
                </Form.Item>
              </Col>
            )
          }
        </Row>
        <Row gutter={[16, 16]} className="artist-upload">
          <Col xs={24} lg={8} className="flex-center-items">
            <Form.Item
              name="branch"
              className="w-100 text-light"
              label={TranslationKey('company.branch')}
              rules={[{
                required: true,
                message: TranslationKey('company.requireBranch')
              }]}>
              <Input
                className="w-100"
                placeholder="example"
                size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8} className="flex-center-items">
            <Form.Item
              name="companyWebsite"
              className="w-100 text-light"
              label={TranslationKey('company.website')}
              rules={[{
                required: true,
                message: TranslationKey('company.requireWebsite')
              }]}>
              <Input
                className="w-100"
                placeholder="example"
                size="large" />
            </Form.Item>
          </Col>
          {
            (companyInfo?.isVip && totalVipDownload > 0) && (
              <>
                <Col xs={24} lg={4} className="flex-center-items">
                  <Form.Item
                    className="w-100"
                    label={TranslationKey('company.paymentTerm')}
                  >
                    {companyInfo.paymentTerm}
                  </Form.Item>
                </Col>
                <Col xs={24} lg={4} className="flex-center-items">
                  <Form.Item
                    className="w-100"
                    label={TranslationKey('company.totalVipDownload')}
                  >
                    {`${vipDownloadQty}/${totalVipDownload}`}
                  </Form.Item>
                </Col>
              </>
            )
          }
        </Row>
        <Divider />
        <Row gutter={[16, 16]} className="artist-upload">
          <Col xs={24} lg={8} className="flex-center-items">
            <Form.Item
              name="address"
              className="w-100 text-light"
              label={TranslationKey('address.title')}
              rules={[{
                required: true,
                message: TranslationKey('address.requireAddress')
              }]}>
              <Input
                className="w-100"
                placeholder="Nexampleame"
                size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="artist-upload">
          <Col xs={24} lg={8} className="flex-center-items">
            <Form.Item
              name="provinceCode"
              className="w-100 text-light"
              label={TranslationKey('address.province')}
              rules={[{
                required: true,
                message: TranslationKey('address.requireProvince')
              }]}>
              <SelectProvince
                className="non-radius"
                provinceID={provinceId}
                sendProvinceID={onProvinceChange}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8} className="flex-center-items">
            <Form.Item
              name="districtCode"
              className="w-100 text-light"
              label={TranslationKey('address.district')}
              rules={[{
                required: true,
                message: TranslationKey('address.requireDistrict')
              }]}>
              <SelectDistrict
                className="non-radius"
                districtID={districtId}
                provinceID={provinceId}
                sendProvinceID={(id) => setProvinceId(id)}
                sendDistrictID={onDistrictChange}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8} className="flex-center-items">
            <Form.Item
              name="subDistrictCode"
              className="w-100 text-light"
              label={TranslationKey('address.subDistrict')}
              rules={[{
                required: true,
                message: TranslationKey('address.requireSubDistrict')
              }]}>
              <SelectSubDistrict
                className="non-radius"
                postCode={postcode}
                districtID={districtId}
                provinceID={provinceId}
                subDistrictID={subDistrictId}
                sendPostCode={(value) => {
                  useFormCompany.setFieldValue('postcode', value);
                }}
                sendSubDistrictID={onSubDistrictChange}
                sendDistrictID={(id, subId) => {
                  setDistrictId(id);
                  setSubDistrictId(subId);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="artist-upload">
          <Col xs={24} lg={8} className="flex-center-items">
            <Form.Item
              name="postcode"
              className="w-100 text-light"
              label={TranslationKey('address.postcode')}
              rules={[{
                required: true,
                message: TranslationKey('address.requirePostcode')
              }]}>
              <Input
                readOnly
                className="non-radius"
                size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Title level={3} className="mb-4">
          {TranslationKey('contactPersonDetail')}
        </Title>
        <Row gutter={[16, 16]} className="artist-upload">
          <Col xs={24} lg={8} className="flex-center-items">
            <Form.Item
              name="contactFirstName"
              className="w-100 text-light"
              label={TranslationKey('individual.firstName')}
              rules={[{
                required: true,
                message: TranslationKey('individual.requireFirstName')
              }]}>
              <Input className="w-100" placeholder="example" size='large' />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8} className="flex-center-items">
            <Form.Item
              name="contactLastName"
              className="w-100 text-light"
              label={TranslationKey('individual.lastName')}
              rules={[{
                required: true,
                message: TranslationKey('individual.requireLastName')
              }]}>
              <Input className="w-100" placeholder="example" size='large' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="artist-upload">
          <Col xs={24} lg={8} className="flex-center-items">
            <Form.Item
              name="contactEmail"
              className="w-100 text-light"
              label={TranslationKey('individual.email')}
              rules={[{
                type: 'email',
                message: TranslationKey('individual.invalidEmailFormat')
              },
              {
                required: true,
                message: TranslationKey('individual.requireEmail')
              }]}>
              <Input
                type="email"
                className="w-100"
                placeholder="example" size='large' />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8} className="flex-center-items">
            <Form.Item
              name="contactPhone"
              className="w-100 text-light"
              label={TranslationKey('individual.phone')}
              rules={[{
                required: true,
                message: TranslationKey('individual.requirePhoneNumber')
              }]}>
              <Input className="w-100" placeholder="example" size='large' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="artist-upload">
          <Col xs={24} lg={8} className="flex-center-items">
            <Form.Item
              name="jobTitle"
              className="w-100 text-light"
              label={TranslationKey('company.jobTitle')}
              rules={[{
                required: true,
                message: TranslationKey('company.requireJobTitle')
              }]}>
              <Input
                className="w-100"
                placeholder="example" size='large' />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8} className="flex-center-items">
            <Form.Item
              name="department"
              className="w-100 text-light"
              label={TranslationKey('company.department')}
              rules={[{
                required: true,
                message: TranslationKey('company.requireDepartment')
              }]}>
              <Input
                className="w-100"
                placeholder="example" size='large' />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8} className="flex-center-items">
            <Form.Item
              name="purchasingFor"
              className="w-100 text-light"
              label={TranslationKey('company.purchasingFor')}
              rules={[{
                required: true,
                message: TranslationKey('company.requirePurchasingFor')
              }]}>
              <Input
                className="w-100"
                placeholder="example" size='large' />
            </Form.Item>
          </Col>
        </Row>

      </Form>
    </Content>
  );
};

export default FormCompany;
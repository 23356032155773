import { Col, Row, Form, Input, Button, Typography, Table, Badge, Card, Pagination, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { DatePicker } from 'antd';
import IDownloadHistoryRequest from '@/src/models/request/downloadHistoryRequest';
import { IDownloadHistoryResponse } from '@/src/models/responses/downloadHistoryModel';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { DateFormat } from '../../../src/constant/DateFormatConstants';
import { UserAssetLicenseService } from '../../../src/services/userAssetLicenseService';
import GalleryItem from 'src/components/galleryItem/GalleryItem';
import { RouteNames } from 'src/routes/routeName';
import { Link } from 'react-router-dom';
import { RangePickerProps } from 'antd/es/date-picker';
import { HttpStatus } from 'src/constant/responseStatus';
import { AssetPublishModel } from 'src/models/assetModel';
import { AssetType } from 'src/constant/assetConstants';
import { BlobFileExtension } from 'src/constant/stringConstants';
import TranslationKey from 'src/i18n/translation';

const { Text } = Typography;

const { RangePicker } = DatePicker;

export default function DownloadHistory() {
  const [form] = Form.useForm();
  const userAssetLicense = new UserAssetLicenseService();
  const dateFormat = 'YYYY-MM-DD';
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [totalRow, setTotalRow] = useState(0);
  const [assetCode, setAssetCode] = useState('');
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [downloadLoadings, setDownloadLoadings] = useState<boolean>();
  const [downloadHistories, setDownloadHistory] = useState<IDownloadHistoryResponse[]>([]);

  const onSearchAsync = async () => {
    await getDownloadHistoryAsync(page, size, assetCode, startDate, endDate);
  };

  const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    if (dates) {
      setStartDate(new Date(dateStrings[0]));
      setEndDate(new Date(dateStrings[1]));
    } else {
      setStartDate(undefined);
      setEndDate(undefined);
    }
  };

  const columns: ColumnsType<IDownloadHistoryResponse> = [
    {
      title: TranslationKey('downloadHistory.assetCode'),
      key: 'assetCode',
      dataIndex: 'assetCode',
      align: 'center',
      width: 120,
      render: (value, record, index) => <Text>{record.asset.code}</Text>,
    },
    {
      title: TranslationKey('downloadHistory.downloadDate'),
      key: 'downloadDate',
      dataIndex: 'downloadDate',
      align: 'center',
      width: 180,
      render: (value, record, index) => <Text>{moment(record.downloadDate).format(DateFormat.DateTime)}</Text>,
    },
    {
      title: TranslationKey('downloadHistory.description'),
      key: 'id',
      render(value, record, index) {
        return (
          <Row wrap={false} align='middle' gutter={[16, 0]}>
            <Col flex='none'>
              <GalleryItem
                index={index}
                asset={record.asset}
                width='50px'
                height='50px'
                iconsize='2em'
                onClickItem={onClickItem}
              />
            </Col>
            <Col flex='auto'>
              <Link to={RouteNames.assetDetail.replace(':id', record.asset.id)}>
                <Tooltip
                  title={
                    <>{record.asset.code}{record.asset.name && ('- ' + record.asset.name)}</>
                  }>
                  <span className='text-ellipsis-2r'>
                    {record.asset.code}{record.asset.name && ('- ' + record.asset.name)}
                  </span>
                </Tooltip>
              </Link>
            </Col>
          </Row>
        );
      },
      dataIndex: 'title',
      width: 400,
    },
    {
      title: TranslationKey('downloadHistory.standardLicense'),
      dataIndex: 'standardLicenses',
      key: 'standardLicenses',
      align: 'center',
      width: 200,
    },
    {
      title: TranslationKey('downloadHistory.extendedLicense'),
      dataIndex: 'extendLicenses',
      key: 'extendLicenses',
      align: 'center',
      width: 250,
      render: (value, record, index) => (
        record.extendLicenses !== 'VIP' ? record.extendLicenses : 'Comp'
      ),
    },
    {
      title: TranslationKey('downloadHistory.downloadType'),
      dataIndex: 'downloadType',
      key: 'downloadType',
      align: 'center',
      width: 150,
      render: (value, record, index) => (
        <Badge color={record.extendLicenses !== "VIP"
          ? '#1677ff'
          : '#fa8c16'} count={record.extendLicenses !== "VIP" ? 'License' : 'Comp'} />
      ),
    },
    {
      title: TranslationKey('downloadHistory.download'),
      dataIndex: 'download',
      key: 'download',
      align: 'center',
      width: 130,
      render: (value, record, index) => (
        <Button
          type='primary'
          shape='round'
          className='btn-primary'
          size='large'
          onClick={() => {
            onDownload(record.asset);
          }}
          loading={downloadLoadings}
          block
        >
          {TranslationKey('downloadHistory.download')}
        </Button>
      ),
    },
  ];

  const getDownloadHistoryAsync = async (
    page: number,
    size: number,
    assetCode?: string,
    downloadFrom?: Date,
    downloadUntil?: Date,
  ) => {
    var search = {
      assetCode,
      downloadFrom,
      downloadUntil,
    } as IDownloadHistoryRequest;

    var response = await userAssetLicense.getListAsync(search, page, size);

    if (response.status !== HttpStatus.OK) {
      return;
    }

    var data = response.data.list as IDownloadHistoryResponse[];
    setTotalRow(response.data.totalRows);
    setDownloadHistory(data);
  };

  const onClickItem = (assetId: string) => {
    window.location.href = RouteNames.assetDetail.replace(':id', assetId);
  };

  useEffect(() => {
    getDownloadHistoryAsync(page, size, assetCode, startDate, endDate);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangePage = async (page: number, size: number) => {
    setPage(page);
    setSize(size);

    await getDownloadHistoryAsync(page, size, assetCode, startDate, endDate);
  };

  const onReset = async () => {
    form.resetFields(['assetCode', 'downloadDate']);
    setAssetCode('');
    setStartDate(undefined);
    setEndDate(undefined);
    await getDownloadHistoryAsync(1, 10, undefined, undefined, undefined);
    setPage(1);
    setSize(10);
  };

  const onDownload = async (asset: AssetPublishModel) => {
    setDownloadLoadings(true);

    const { data: responseData, status: responseStatus } = await new UserAssetLicenseService().downloadByAssetIdAsync(
      asset.id
    );

    if (responseStatus !== HttpStatus.OK) {
      setDownloadLoadings(false);
      return;
    }

    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(responseData);
    downloadLink.download = asset?.assetType === AssetType.IMAGE
      ? `alive_${asset!.code}${BlobFileExtension.Image}`
      : `alive_${asset!.code}${BlobFileExtension.Video}`;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    setDownloadLoadings(false);
  };

  return (
    <>
      <Card title={TranslationKey('downloadHistory.title')} bordered={false}>
        <Form form={form} layout='vertical' onFinish={onSearchAsync} className='form-input'>
          <Row className='d-flex gap-2 justify-content-center'>
            <Col span={24} lg={6} className='flex-center-items'>
              <Form.Item
                name='assetCode'
                className='text-light w-100'
                label={TranslationKey('assetCode')}
                rules={[{ required: false }]}
              >
                <Input
                  className='w-100'
                  placeholder={TranslationKey('assetCode')}
                  size='large'
                  onChange={(e) => setAssetCode(e.target.value)} />
              </Form.Item>
            </Col>
            <Col span={24} lg={6} className='flex-center-items'>
              <Form.Item
                name='downloadDate'
                className='text-light w-100'
                label={TranslationKey('downloadHistory.downloadDate')}
                rules={[{ required: false }]}
              >
                <RangePicker
                  format={dateFormat}
                  onChange={onChange} size='large' />
              </Form.Item>
            </Col>
          </Row>
          <Row className='d-flex gap-2 justify-content-center'>
            <Col xs={12} md={4}>
              <Button shape='default' type='primary' className='w-100' size='large' htmlType='submit'>
                {TranslationKey('button.search')}
              </Button>
            </Col>
            <Col xs={12} md={4}>
              <Button shape='default' type='default' className='w-100' size='large' onClick={onReset}>
                {TranslationKey('button.clear')}
              </Button>
            </Col>
          </Row>
        </Form>
        <Row className='mt-2'>
          <Col span={24}>
            <Table
              locale={{ emptyText: TranslationKey("table.noData") }}
              scroll={{ x: true }}
              columns={columns}
              dataSource={downloadHistories}
              pagination={false} />
          </Col>
          <Col span={24} className='text-right pt-3'>
            <Pagination current={page} defaultCurrent={1} total={totalRow} onChange={onChangePage} />
          </Col>
        </Row>
      </Card>
    </>
  );
}

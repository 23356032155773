import { AssetType } from "src/constant/assetConstants";
import { Card, Checkbox, Col, Row, Watermark } from "antd";
import EditingAssetModel from "../../../models/request/editingAssetRequestModel";
import HoverVideoPlayer from "react-hover-video-player";
import { useState } from "react";
import { CheckboxProps } from "antd/lib/checkbox";
import TranslationKey from "src/i18n/translation";
import { PlayCircleOutlined } from "@ant-design/icons";

interface Props {
  watermaskContent: string;
  myUpload: EditingAssetModel[];
  onSelectedItem: (item: EditingAssetModel) => void;
  onSelectedItemAll: (item: EditingAssetModel[]) => void;
}

export default function AssetThumnail(
  {
    watermaskContent,
    myUpload,
    onSelectedItem,
    onSelectedItemAll,
  }: Props) {
  const [isCheckAll, setIsCheckAll] = useState(false);

  const vidoeThumbailPlaceholder = require('../../../assets/images/my-upload/video_thumnail.png');
  const watermarkPropsConfig = {
    content: watermaskContent,
    font: {
      color: `rgba(255,255,255,0.4)`,
      fontSize: 20,
    },
    rotate: 0,
    zIndex: 11,
    gap: [32, 32] as [number, number],
  };

  const updateIsCheckedAll = () => {
    const isCheckedAll =
      myUpload &&
      myUpload.length > 0 &&
      myUpload.length === myUpload.filter(m => m.isSelected).length;

    setIsCheckAll(isCheckedAll);
  }

  const handleSelectItem = function (e: any, index: number) {
    myUpload[index].isSelected = e.target.checked;

    onSelectedItem(myUpload[index]);
    updateIsCheckedAll();
  }

  const onCheckAllChange: CheckboxProps['onChange'] = (e) => {
    setIsCheckAll(e.target.checked);

    onSelectedItemAll(e.target.checked ? myUpload : []);

    if (myUpload && myUpload.length > 0) {
      myUpload.forEach(data => {
        if (data.thumbnailImageInfo) {
          data.isSelected = e.target.checked;
        }
      });
    }
  };

  return (
    <>
      <Row justify={"end"}>
        <Col>
          <Checkbox
            checked={isCheckAll}
            onChange={onCheckAllChange}>
            {TranslationKey("myUpload.selectAll")}
          </Checkbox>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ display: 'contents' }}>
          {
            myUpload.map((mu, muIndex) =>
              <Card key={'asset_item_' + muIndex}
                className="card-thumnail"
                bodyStyle={{ padding: '0px' }}>
                <Watermark {...watermarkPropsConfig}>
                  {
                    mu.assetType === AssetType.IMAGE &&
                    <img alt={mu.fileName}
                      className="card-cover-image"
                      src={mu.thumbnailImageInfo.imageUrl} />
                  }
                  {
                    mu.assetType === AssetType.VIDEO &&
                    (
                      mu.thumbnailImageInfo
                        ?
                        <HoverVideoPlayer
                          videoSrc={mu.thumbnailImageInfo.vdoUrl}
                          restartOnPaused
                          overlayTransitionDuration={500}
                          videoStyle={{
                            height: '150px',
                            width: '150px',
                            objectFit: 'cover',
                            borderRadius: '8px'
                          }}
                          pausedOverlay={
                            <div style={{ position: 'absolute' }}>
                              <div style={{
                                position: 'absolute',
                                left: '50%',
                                top: '50%',
                                right: '50%',
                                bottom: '50%',
                              }}>
                                <PlayCircleOutlined
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    fontSize: "3rem",
                                    color: "black",
                                  }}
                                />
                              </div>
                              <img src={mu.thumbnailImageInfo.vdoCoverUrl}
                                style={{ objectPosition: 'center' }}
                                className="gallery-img"
                                alt={mu.fileName} />
                            </div>
                          }
                          loadingOverlay={
                            <div className="loading-overlay">
                              {TranslationKey("myUpload.onLoading")}
                            </div>
                          }
                        />
                        :
                        <div style={{ position: 'absolute' }}>
                          <div style={{
                            position: 'absolute',
                            left: '5px',
                            bottom: '5px',
                          }}>
                            {TranslationKey("myUpload.onConverting")}
                          </div>
                          <img
                            alt={mu.fileName}
                            className="card-cover-image"
                            src={vidoeThumbailPlaceholder} />
                        </div>
                    )
                  }
                </Watermark>
                {
                  mu.thumbnailImageInfo &&
                  <div className="checkbox-thumnail">
                    <Checkbox
                      checked={mu.isSelected}
                      onChange={(e) => { handleSelectItem(e, muIndex) }} />
                  </div>
                }
              </Card>)
          }
        </Col>
      </Row>
    </>
  );
}
import { Col, Row, Form, Input, Button, Card, Space, Pagination } from 'antd';
import { useEffect, useState } from 'react';
import { DatePicker } from 'antd';
import { IOrderHistoryResponse } from '@/src/models/responses/orderHistoryResponse';
import { OrderService } from '../../../src/services/orderService';
import IOrderHistoryRequest from 'src/models/request/orderHistoryRequest';
import CardOrderItem from 'src/components/card/CartOrderItem';
import { HttpStatus } from 'src/constant/responseStatus';
import TranslationKey from 'src/i18n/translation';

const { RangePicker } = DatePicker;

export default function OrderHistory() {
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [totalRow, setTotalRow] = useState(0);

  const [orders, setOrder] = useState<IOrderHistoryResponse[]>([]);

  const onSearch = () => {
    getOrderHistory(page, size);
  };

  const getOrderHistory = async (page: number, size: number) => {
    const pDate = form.getFieldValue('orderDate');

    const search = {
      page: page,
      size: size,
      orderNo: form.getFieldValue('orderNo'),
      orderFrom: pDate ? new Date(new Date(pDate[0]).setHours(0, 0, 0, 0)) : undefined,
      orderTo: pDate ? new Date(new Date(pDate[1]).setHours(24, 0, 0, 0)) : undefined,
    } as IOrderHistoryRequest;

    const { data, status } = await new OrderService().getOrderHistoryAsync(search);

    if (status !== HttpStatus.OK) {
      return;
    }

    setTotalRow(data.paging.totalRows);
    setOrder(data.list);
  };

  const onChangePage = (page: number, size: number) => {
    setPage(page);
    setSize(size);
    getOrderHistory(page, size);
  };

  useEffect(() => {
    getOrderHistory(page, size);
  }, []);

  const onReset = async () => {
    form.resetFields(['orderNo', 'orderDate']);

    setPage(1);
    setSize(10);

    await onSearch();
  };

  return (
    <>
      <Card title={TranslationKey('orderHistory.title')} bordered={false}>
        <Form form={form} layout='vertical' onFinish={onSearch} className='form-input'>
          <Row gutter={[16, 5]} className='artist-upload mb-4'>
            <Col span={24} lg={6} className='flex-center-items'>
              <Form.Item
                name='orderNo'
                className=' text-light w-100'
                label={TranslationKey('orderHistory.orderNumber')}>
                <Input className='w-100' placeholder='Name' size='large' />
              </Form.Item>
            </Col>
            <Col span={24} lg={6} className='flex-center-items'>
              <Form.Item
                name='orderDate'
                className=' text-light w-100'
                label={TranslationKey('orderHistory.orderDate')}>
                <RangePicker size='large' />
              </Form.Item>
            </Col>
            <Col xs={24} lg={4} className='flex-center-items gap-2'>
              <Button size='large'
                shape='default'
                type='primary'
                className='w-100'
                htmlType='submit'>
                {TranslationKey('button.search')}
              </Button>
              <Button shape='default'
                type='default'
                className='w-100'
                size='large'
                onClick={onReset}>
                {TranslationKey('button.clear')}
              </Button>
            </Col>
          </Row>
        </Form>
        <Space direction='vertical'
          size='middle'
          style={{ display: 'flex' }}>
          {
            orders.length === 0
              ?
              <div className='text-center'>
                {TranslationKey('table.noData')}
              </div>
              :
              orders.map((order, index) => {
                return (
                  <CardOrderItem
                    order={order}
                    key={index}></CardOrderItem>
                );
              })
          }
        </Space>
        <Col span={24} className='text-right pt-3'>
          <Pagination
            defaultCurrent={1}
            current={page}
            total={totalRow}
            pageSize={size}
            onChange={onChangePage}
          />
        </Col>
      </Card>
    </>
  );
}
import { RecommendCollectionItemModel } from '../../models/BannerModel';
import { Col, Skeleton } from 'antd';
import { useEffect, useState } from 'react';
import { BannerService } from '../../services/bannerService';
import { HttpStatus } from '../../constant/responseStatus';
import { useNavigate } from 'react-router-dom';
import { RouteNames } from 'src/routes/routeName';
import CollectionItem from './CollectionItem';

export default function CollectionAll() {
    const [recommendItem, setRecommendItem] = useState<RecommendCollectionItemModel[]>([]);
    const [activeCollection, setActiveCollection] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        onGetRecommendCollection();
    }, []);

    const onGetRecommendCollection = async () => {
        const {
            data: responseData,
            status: responseStatus
        } = await new BannerService().GetRecommendCollection();

        if (responseStatus !== HttpStatus.OK) {
            return;
        }

        const collectionItems =
            responseData.flatMap(
                collectionItem => collectionItem.recommendCollectionItems);


        setActiveCollection(true);
        setRecommendItem(collectionItems);
    };

    const onClickItem = (collectionId: string) => {
        navigate(RouteNames.collection.replace(':id', collectionId));
    };

    return (
        <>
            {activeCollection
                ? recommendItem.map((ri, index) => (
                    <Col
                        className='mt-1'
                        key={index}
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}
                        xl={{ span: 6 }}
                    >
                        <CollectionItem
                            collectionIndex={index}
                            collectionId={ri.collectionId}
                            collectionName={ri.collection.name}
                            collectionItems={ri.collection.collectionItems}
                            artistId={ri.collection.userId}
                            nameColor='#fff'
                            onClickItem={onClickItem}

                        />
                    </Col>
                ))
                : Array.from({ length: 4 }).map((_, index) => (
                    <Col
                        className='mt-1'
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}
                        xl={{ span: 6 }}
                        key={index}
                    >
                        <div className='box-collection'>
                            <Skeleton.Image active />
                        </div>
                        <div className='box-collection-sm'>
                            {Array.from({ length: 3 }).map((_, index) => (
                                <div className='img' key={index}>
                                    <Skeleton.Image active />
                                </div>
                            ))}
                        </div>
                        <div className='box-collection-detail mt-3'>
                            <Skeleton active />
                        </div>
                    </Col>
                ))}
        </>
    );
}